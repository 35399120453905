var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"customers-list"}},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary"},on:{"click":_vm.addCustomer}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("New customer")])],1)],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Search","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.customersList,"options":_vm.options,"server-items-length":_vm.totalCustomers,"loading":_vm.loading,"footer-props":{'items-per-page-options':[10, 25, 50, 100]}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.company_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(item.company_name))])]}},{key:"item.vat_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vat_number)+" ")]}},{key:"item.contact_person",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.contact_person))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editCustomer(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('customer-form',{attrs:{"customer":_vm.customerData},on:{"close-dialog":function($event){_vm.dialog = false},"refresh-list":_vm.fetchCustomers}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }