<template>
  <v-card id="customers-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <v-btn
          color="primary"
          class="me-3"
          @click="addCustomer"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>New customer</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
            v-model="searchQuery"
            :append-icon="icons.mdiMagnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      :headers="tableColumns"
      :items="customersList"
      :options.sync="options"
      :server-items-length="totalCustomers"
      :loading="loading"
      class="text-no-wrap"
      :footer-props="{'items-per-page-options':[10, 25, 50, 100]}"
    >

      <!-- id -->
      <template #[`item.id`]="{item}">
          {{ item.id }}
        </router-link>
      </template>

      <template #[`item.company_name`]="{item}">
        <span class="font-weight-semibold">{{ item.company_name }}</span>
      </template>

      <template #[`item.vat_number`]="{item}">
        {{ item.vat_number }}
      </template>

      <template #[`item.contact_person`]="{item}">
        <span class="text-no-wrap">{{ item.contact_person }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <v-icon
          small
          class=""
          @click="editCustomer(item)"
        >
          {{ icons.mdiPencilOutline }}
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <customer-form
        :customer="customerData"
        @close-dialog="dialog = false"
        @refresh-list="fetchCustomers"
      ></customer-form>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiDownloadOutline,
  mdiPencilOutline,
  mdiContentCopy,
  mdiMagnify
} from '@mdi/js'

import { onUnmounted, ref, watch } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import CustomerForm from './CustomerForm.vue'

// store module
import invoiceStoreModule from '../invoice/invoiceStoreModule'

export default {
  components: {
    CustomerForm
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const customersList = ref([])
    const totalCustomers = ref(0)
    const dialog = ref(false)
    const customerData = ref({})

    // Table Handlers
    const tableColumns = [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
      },
      { text: 'COMPANY NAME', value: 'company_name' },
      { text: 'VAT NUMBER', value: 'vat_number' },
      { text: 'CONTACT PERSON', value: 'contact_person' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const searchQuery = ref('')
    const loading = ref(false)
    const options = ref({
      sortBy: ['company_name'],
      sortDesc: [],
      itemsPerPage: 10
    })

    const fetchCustomers = () => {
      store.dispatch('app-invoice/fetchClients', {
        q: searchQuery.value,
        options: options.value
      }).then(response => {
        const { filteredData, total } = response.data
        customersList.value = filteredData
        totalCustomers.value = total
        loading.value = false
      }).catch(error => {
        console.log(error)
      })
    }

    const addCustomer = () => {
      dialog.value = true
    }

    const editCustomer = (item) => {
      customerData.value = item
      dialog.value = true
    }

    watch([searchQuery,options], () => {
      // start loading
      loading.value = true
      fetchCustomers()
    })

    return {
      tableColumns,
      searchQuery,
      options,
      loading,
      customersList,
      totalCustomers,
      dialog,
      customerData,
      editCustomer,
      addCustomer,
      fetchCustomers,
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiContentCopy,
        mdiDownloadOutline,
        mdiPencilOutline,
        mdiMagnify
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
